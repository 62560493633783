import React from 'react';
import _ from 'lodash';
import { NextSeo } from 'next-seo';
import classnames from 'classnames';
import { parse } from 'url';

import ApiClient from '@/utils/ApiClient';
import { replaceFields, urlize } from '@/utils/misc';
import { getRootStyles } from '@/helpers/DOM/css';
import { LayoutLocationContext } from '@/globals/contexts';
import { isDev } from '@/helpers/env';

import Layout from '@/Layout';

const fetchStandardPage = async (ctx, { pageId }) => {
  const apiClient = ctx?.apiForServer || ApiClient;
  const path = `${apiClient.instanceName}/rendering/pages/${pageId}`;

  return apiClient._get(path);
};

export default class StandardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      layout: props.layout,
    };
  }

  static async getInitialProps(ctx, pageIdFromDerivedClass = null) {
    const { pathname, search } = parse(ctx.asPath);
    const { page, layout } = await fetchStandardPage(ctx, ctx.query);
    if (page && pathname !== page.path && ctx.res) {
      ctx.res.writeHead(isDev() ? 302 : 301, {
        Location: page.path + (search || ''),
      });
      ctx.res.end();
    }
    return { page, layout };
  }

  render() {
    let { page, layout } = this.state;
    const { variables, siteContext, baseUrl } = this.props; // ex. from search
    const {
      siteConfig: { options: siteOptions },
    } = siteContext;

    if (variables) {
      page = _.mapValues(page, (v) =>
        _.isString(v) ? replaceFields(v, variables) : v
      );
    }

    if (!page?.templateLayout) return null;

    const canonicalUrl = `${baseUrl}${page.path}`;

    // Classes
    const className = classnames('Page', page.className, urlize(page.label));

    return (
      <>
        <NextSeo
          title={page.seoLabel}
          description={page.seoDescription}
          canonical={canonicalUrl}
          noindex={page.disableSeoIndexation}
          nofollow={page.disableSeoIndexation}
        />

        <div className={className} style={getRootStyles(page, siteOptions)}>
          <div
            className={
              ((page.className && page.className + '-body ') || '') +
              'Page-body Page--' +
              page._id
            }
            role="main"
          >
            <LayoutLocationContext.Provider
              value={{
                source: `StandardPage:${page._id}:templateLayout`,
              }}
            >
              <Layout layout={layout} isPageWrapper />
            </LayoutLocationContext.Provider>
          </div>
        </div>
      </>
    );
  }
}
